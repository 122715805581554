import { FormatRegistry } from '@sinclair/typebox';
import { IsDate } from './formats/date';
import { IsDateTime } from './formats/date-time';
import { IsEmail } from './formats/email';
import { IsTime } from './formats/time';
import { IsUuid } from './formats/uuid';

export function registerHandlers() {
	FormatRegistry.Set('date-time', (value) => IsDateTime(value));
	FormatRegistry.Set('date', (value) => IsDate(value));
	FormatRegistry.Set('time', (value) => IsTime(value));
	FormatRegistry.Set('uuid', (value) => IsUuid(value));
	FormatRegistry.Set('email', (value) => IsEmail(value));
}
